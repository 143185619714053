
.container{
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  padding-left: 10px;
}
.item{
  margin:5px
}
.itemContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5x;
}
.labelColor{
  font-size: small;
  font-variant-caps: all-petite-caps;
}
.selectedItem{
  margin:5px;
  border:1px solid rgb(30, 13, 126);
  border-width: 1px;
}
.root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  min-height: 75vh;
  
}
.item-container {
  display: block;
  align-items: center;
  width:408px;
  padding-top: 20px;
  padding-left: 10px;
}
.items {
  width: 200px;
  padding: 1px;
  min-height: 10px;
  font-size: xx-small;
}
.menuItems {
  max-height: 20px !important;
  font-size: xx-small;
}
.itemProps {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  vertical-align: middle;
  padding-top: 3px;
}
.itemPropsButton {
    padding-top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.radioItems{
    width: 300px;
    padding-left: 10px;

}
.customerDiv{
  display: flex;
  direction: row;
  justify-content:center;
  justify-items: center;
  align-items: center;
}
.addbtn{
  padding-Left:3px;
  cursor:pointer;
  align-items: center;
  
}
.group-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.price-Label-subtotal{
  font-size: x-large;
}
.price-Label{
  font-size: xx-large;
}
.mainDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    min-height: 100vh;

}
.component{

    border-radius: 10px;
    width: 300px;
    height: 270px;
    margin-top: 10px;
    padding: 5px;
    column-gap: 5px;
}

.items{
    
    padding: 10px;
    margin-top: 10px;

}
.buttondiv{
    margin-top: 10px;
    padding-left: 60px;
    padding-right: 60px;
    display: flex;
    direction: row;
    justify-content: space-between;
}
h3{
    align-items: center;
    text-align: center;
}
.spacer{
    height: 10px;
}
 img{
    display: block;
   margin-left: auto;
   margin-right: auto;
}
.popup-OuterContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    height: 100vh;
}

.popup{
    background-color:white;
    width:30%;
    min-width: 400px;
    display: flex;
    align-items: center;
border-radius: 8px;

}
.dialog-out
{
    padding:10px;
}
.dialog-out-in{
    display:  flex;
    flex-direction: column;
    row-gap: 7px;
    align-items: center;
    padding-left: 10px;
}
.customh3{
    display:  flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.buttonGroup{
    display:  flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
}